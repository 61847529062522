










import Vue, { PropOptions, PropType } from 'vue';

import { resetCSSAndDOM } from './utils';
import TheContent from '~/components/content/TheContent';
import separateScriptsAndContent from '~/libs/separateScriptsAndContent';
import { Scripts } from '~/libs/Scripts';

let scriptsAdder: null | IScripts = null;

export default Vue.extend({
  name: 'HtmlWidgetContainer',
  components: { TheContent },
  props: {
    widgetsArray: { type: Array, required: true } as PropOptions<TContent[]>,
    location: {
      type: String as PropType<string>,
      // required: true,
      default: null,
    },
  },
  computed: {
    arrayOfHTMLContents (): TContent[] {
      return separateScriptsAndContent(this.widgetsArray).content;
    },
    routePath (): string {
      return this.$route.path;
    },
    adfoxWidgets (): TContent[] {
      const adfoxWidgetsContainer: TContent[] = [];

      fillContainer(this.widgetsArray);

      function fillContainer (contentArray: TContent[]): void {
        contentArray.forEach((contentItem: TContent) => {
          const idAttribute = contentItem?.attributes?.id;
          if (idAttribute !== undefined && idAttribute.includes('adfox_')) {
            adfoxWidgetsContainer.push(contentItem);
          }
          // рекурсивно получаем адфокс-виджеты
          if (contentItem.children.length) {
            fillContainer(contentItem.children);
          }
        });
      }

      return adfoxWidgetsContainer;
    },
  },
  watch: {
    routePath () {
      this.reloadAdfoxWidgets();
    },
  },
  mounted () {
    if (this.widgetsArray.length) {
      const { inlineScripts, scripts } = separateScriptsAndContent(this.widgetsArray);

      scriptsAdder = new Scripts(scripts, inlineScripts);
      scriptsAdder.addScripts();
    }
  },
  beforeDestroy () {
    // вызываем destroy т.к. adfox вешает cb на scroll,
    // в котором устанавливает padding-bottom: 120px
    this.adfoxWidgets.forEach((w: TContent) => {
      const idAttribute = w?.attributes?.id;
      if (idAttribute) {
        // eslint-disable-next-line no-unused-expressions
        window?.Ya?.adfoxCode?.destroy(idAttribute);
      }
    });
    // после destroy в resetCSSAndDOM сбрасывается padding-bottom
    resetCSSAndDOM();
    scriptsAdder && scriptsAdder.removeScripts();
  },
  methods: {
    reloadAdfoxWidgets () {
      this.adfoxWidgets.forEach((w: TContent) => {
        const idAttribute = w?.attributes?.id;
        if (idAttribute) {
          // eslint-disable-next-line no-unused-expressions
          window?.Ya?.adfoxCode?.reload(idAttribute);
        }
      });
    },
  },
});
