

















import Vue from 'vue';
import { getFullUrl, isRequiresAuth } from '~/utils';

import TopHeader from '~/components/TopHeader/TopHeader.vue';
import TopHeaderSearch from '~/components/TopHeaderSearch/TopHeaderSearch.vue';
import TopHeaderSearchActionsheet from '~/components/TopHeaderSearch/TopHeaderSearchActionsheet.vue';
import { SHOW_NAV, LOGOUT } from '~/constants';
import type { Menus } from '~/store/menus';
import LoginModal from '~/components/LoginModal.vue';
import { leavePrivateChannel, pusherResetStore } from '~/components/Pusher/websockets-functions';

export default Vue.extend({
  name: 'TopHeaderContainer',
  components: {
    TopHeader,
    TopHeaderSearch,
  },
  data () {
    return {
      searchActive: false as boolean,
    };
  },
  computed: {
    primaryNavMenuItems (): Array<MenuItem> {
      return (this.$store.getters.menus as Menus)?.projects?.items ?? [];
    },
    user (): IUser | null {
      const { user } = this.$store.state as IRootState;

      return user ?? null;
    },
    userIntegerId (): number | null {
      return this.user?.integer_id ?? null;
    },
    userAvatarSrc (): string {
      const { user } = this;

      if (user) {
        const smallAvatar = user.avatars.find(
          avatar => avatar.size === 'small',
        );
        const middleAvatar = user.avatars.find(
          avatar => avatar.size === 'middle',
        );
        if (middleAvatar && middleAvatar.url) {
          return middleAvatar.url;
        }
        if (smallAvatar && smallAvatar.url) {
          return smallAvatar.url;
        }
      }
      return '';
    },
    isAuthenticated (): boolean {
      // const { user } = this.$store.state as IRootState;
      // return user !== null;

      return Boolean(this.user);
    },
  },
  methods: {
    doAuthorization (location = 'колокольчик'): void {
      // @ts-ignore
      window.accounts?.id?.cancel();

      this.$modal.showModal(LoginModal, {
        container: 'windows',
        transition: 'modal-fade',
        name: 'LoginModal',
        disableScroll: true,
      }, {
        eventElement: location,
      },
      );
    },
    logout () {
      const requiresAuth = isRequiresAuth(this.$route);
      if (requiresAuth) {
        const { href } = this.$router.resolve({
          path: '/',
          query: this.$route.query,
        });
        location.href = href;
      }
      leavePrivateChannel();
      pusherResetStore(this.$store);
      this.$store.dispatch(LOGOUT);
    },
    showNav () {
      this.$store.commit(SHOW_NAV);
    },
    onClickOnLogo (): void {
      this.$sendYandexMetrika({
        level1: 'Клик_Лого',
        level4: getFullUrl('/'),
      });
    },
    onClickSearch () {
      if (window.innerWidth < 768) {
        this.$modal.showModal(TopHeaderSearchActionsheet, {
          transition: 'modal-fade',
          name: 'TopHeaderSearchActionsheet',
          container: 'windows',
          disableScroll: true,
        });
      } else {
        this.searchActive = true;
      }
    },
    closeSearch () {
      this.searchActive = false;

      if (window.innerWidth < 768) {
        this.$modal.hideModal('windows', 'TopHeaderSearchActionsheet');
      }
    },
  },
});
