


















import Vue from 'vue';
import NavMenuCategories from '~/components/NavMenu/NavMenuCategories.vue';
import NavMenuSocials from '~/components/NavMenu/NavMenuSocials.vue';
import { isHealthPath } from '~/router';

// main menu
export default Vue.extend({
  name: 'TheSidebarContainerAmp',
  components: {
    NavMenuCategories,
    NavMenuSocials,
  },

  computed: {
    burgerMenuItems (): Array<MenuItem> {
      return this.$store.getters.burgerMenuItems;
    },
    socialNetwork (): any {
      return this.$store.getters.menus.burgerSubs;
    },
    socialTitle (): string {
      return this.socialNetwork?.title || 'Мы в соцсетях';
    },
    socialItems (): Array<MenuItemWithIcon> {
      return this.socialNetwork?.items;
    },
    isHealthPath (): boolean {
      return isHealthPath(this.$route.path);
    },
  },
});
